import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { GET_ITEM } from '../../Shared/actions';
import { APP_URL, CURRENCY } from '../../Shared/global';
import { Link } from 'react-router-dom'

const HomeMenu = ({ state, dispatch, translate, i18n }) => {
    const randomItems = state.categories
        .filter(category => category.items.length > 0)
        .map(category => {
            const randomIndex = Math.floor(Math.random() * category.items.length);
            return category.items[randomIndex];
        });

    const offerCategory = state.categories
        .filter(category => category.is_offer === 1)[0];
    return (
        <div id="menu" className="container-xxl py-5 home-menu menu">
            <div className="container">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h5 className="section-title ff-secondary text-center text-primary fw-normal">{translate('Food Menu')}</h5>
                    <h1 className="mb-5">{translate('Most Popular Items')}</h1>
                </div>
                <Swiper
                    slidesPerView={2}
                    spaceBetween={30}
                    navigation={true}
                    modules={[Navigation]}
                    breakpoints={{
                        576: {
                            slidesPerView: 3,
                        },
                    }}
                    className="mySwiper px-lg-5 px-0 overflow-hidden"
                    
                    style={{ direction: i18n.language === 'ar' ? 'ltr' : 'ltr' }}
                >
                    {
                        randomItems.map((item, index) => (

                            <SwiperSlide key={index}>
                                <div className="item text-center">
                                    <a href="/#" data-bs-toggle="modal" data-bs-target="#add-item"
                                        onClick={() => {
                                            dispatch({ type: GET_ITEM, payload: item });
                                        }}
                                    >
                                        <div className="card border-0 overflow-hidden">
                                            <div className="imag overflow-hidden">
                                                <img src={APP_URL + item.logom} className="card-img-top" alt="..." />
                                            </div>
                                            <div className="card-body">
                                                <h5 className="card-title">{item.name}</h5>
                                                <small className="card-text d-block text-dark mb-2">{item.short_description}</small>
                                                <strong className="text-primary">{item.price} {translate(CURRENCY)}</strong>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
                <div className="text-center">
                    <Link to="/menu" className="btn btn-primary py-sm-3 px-sm-5 me-3 animated slideInUp rounded-3 fw-bold">{translate('Explore Our Menu')}</Link>
                </div>
            </div>

            {
                offerCategory && (
                    <div className="container mt-5">
                        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                            <h5 className="section-title ff-secondary text-center text-primary fw-normal">{translate('Food Menu')}</h5>
                            <h1 className="mb-5">{translate('Offers')}</h1>
                        </div>
                        <Swiper
                            slidesPerView={2}
                            spaceBetween={30}
                            navigation={true}
                            modules={[Navigation]}
                            breakpoints={{
                                576: {
                                    slidesPerView: 3,
                                },
                            }}
                            className="mySwiper px-lg-5 px-0 overflow-hidden"

                            style={{ direction: i18n.language === 'ar' ? 'ltr' : 'ltr' }}
                        >
                            {
                                offerCategory.items.map((item, index) => (

                                    <SwiperSlide key={index}>
                                        <div className="item text-center">
                                            <a href="/#" data-bs-toggle="modal" data-bs-target="#add-item"
                                                onClick={() => {
                                                    dispatch({ type: GET_ITEM, payload: item });
                                                }}
                                            >
                                                <div className="card border-0 overflow-hidden">
                                                    <div className="imag overflow-hidden">
                                                        <img src={APP_URL + item.logom} className="card-img-top" alt="..." />
                                                    </div>
                                                    <div className="card-body">
                                                        <h5 className="card-title">{item.name}</h5>
                                                        <small className="card-text d-block text-dark mb-2">{item.short_description}</small>
                                                        <strong className="text-primary">{item.price} {translate(CURRENCY)}</strong>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                        <div className="text-center">
                            <Link to="/menu" className="btn btn-primary py-sm-3 px-sm-5 me-3 animated slideInUp rounded-3 fw-bold">{translate('Explore Our Menu')}</Link>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default HomeMenu