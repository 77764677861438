import axios from "axios";
import { VENDOR_ID, APP_URL } from "./global";

const API_V2_URL = APP_URL + "/api/v2";

const CLIENT_URL = API_V2_URL + "/client";
const CLIENT_VENDOR_URL = CLIENT_URL + "/vendor";

const CLIENT_VENDOR_BASE_URL = CLIENT_VENDOR_URL + "/" + VENDOR_ID;

export const vendorApi = axios.create({
    baseURL: CLIENT_VENDOR_URL
});

export const categoriesApi = axios.create({
    baseURL: CLIENT_VENDOR_BASE_URL
});

export const orderApi = axios.create({
    baseURL: CLIENT_URL
})

export const hoursApi = axios.create({
    baseURL: CLIENT_VENDOR_BASE_URL
})