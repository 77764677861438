import React from 'react'
import { Link } from 'react-router-dom'
import { VENDOR_NAME, VENDOR_PHONE } from '../global'

const Footer = ({translate, i18n}) => {
    return (
        <>
            <div className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-3 col-md-6">
                            <h4 className={`section-title ff-secondary text-${i18n.language === 'ar' ? 'end' : 'start'} text-primary fw-normal mb-4`}>{translate('Company')}</h4>
                            <Link className={`btn btn-link text-${i18n.language === 'ar' ? 'end' : 'start'}`} to="/about">{translate('About')}</Link>
                            <Link className={`btn btn-link text-${i18n.language === 'ar' ? 'end' : 'start'}`} to="/contact">{translate('Contact')}</Link>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h4 className={`section-title ff-secondary text-${i18n.language === 'ar' ? 'end' : 'start'} text-primary fw-normal mb-4`}>{translate('Contact')}</h4>
                            <p className="mb-2"><i className={`fa fa-map-marker-alt m${i18n.language === 'ar' ? 's' : 'e'}-3`}></i>الجيزة، حدائق أكتوبر، ، أمام عمارات ٢٤٧ ، ممشى الحسن السياحي،  محل رقم ٩</p>
                            <p className="mb-2"><i className={`fa fa-phone-alt m${i18n.language === 'ar' ? 's' : 'e'}-3`}></i>{VENDOR_PHONE}</p>
                            <div className="d-flex pt-2">
                                {/* <a className="btn btn-outline-light btn-social tiktok" href="https://www.tiktok.com/@original.taste.si?_t=8iRmJxo0CHu&_r=1">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="16" viewBox="0 0 448 512" fill="#fff">
                                        <path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" />
                                    </svg>
                                </a> */}
                                <a className="btn btn-outline-light btn-social" href="https://www.facebook.com/profile.php?id=61556293846478"><i className="fab fa-facebook-f"></i></a>
                                {/* <a className="btn btn-outline-light btn-social" href="https://www.youtube.com/@OriginalTasteSince1999"><i className="fab fa-youtube"></i></a> */}
                                <a className="btn btn-outline-light btn-social" href={`https://wa.me/${VENDOR_PHONE}`}><i className="fab fa-whatsapp"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h4 className={`section-title ff-secondary text-${i18n.language === 'ar' ? 'end' : 'start'} text-primary fw-normal mb-4`}>{translate('Opening Hours')}</h4>
                            <h5 className="text-light fw-normal">{translate('Every Day')}</h5>
                            {
                                i18n.language === 'ar' ? 
                                <p>12:00 مساء - 12:00 صباحا {translate('EG time')}</p> : 
                                <p>12:00 AM - 12:00 PM {translate('EG time')}</p>
                            }
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="copyright">
                        <div className="row justify-content-center">
                            <div className="col-md-12 text-center mb-3 mb-md-0">
                                &copy; <Link className="border-bottom" to="/">{VENDOR_NAME}</Link>, All Right Reserved.

                            </div>
                            <div className="col-md-6 text-center text-md-end">
                                <div className="footer-menu">
                                    {/* <Link to="/">Home</Link>
                                    <a href="/#">Cookies</a>
                                    <a href="/#">Help</a>
                                    <a href="/#">FQAs</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a href="/#" className="btn btn-lg btn-primary btn-lg-square back-to-top d-lg-flex d-md-none d-none"><i className="bi bi-arrow-up"></i></a>
        </>
    )
}

export default Footer