import React, { useReducer, useEffect, useState } from 'react'
import {
    Footer,
    Navbar,
    toast
} from '../../Shared/components';
import {
    UserInformation,
    Items,
    Payment
} from './Components';
import ShoppingCartProvider from '../../Shared/shopping-cart-context'
import reducer, { initialState } from '../../Shared/reducer';
import {
    GET_CART_ITEMS,
    GET_TIME_SLOTS,
    SUBMIT_SUCCESS,
    SUBMIT_ERROR
} from '../../Shared/actions';
import { orderApi } from '../../Shared/axios-config';
import { CURRENCY, VENDOR_ID, VENDOR_PHONE } from '../../Shared/global';
import EmptyCart from '../../Shared/components/EmptyCart';

const CheckOut = ({ translate, i18n }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        dispatch({ type: GET_CART_ITEMS, payload: dispatch })
        dispatch({ type: GET_TIME_SLOTS, payload: dispatch })
    }, [])

    const postOrder = async (e) => {
        e.preventDefault();

        if (!checkValidation()) {
            setLoading(false);
            return;
        }

        const reqBody = {
            vendor_id: VENDOR_ID,
            delivery_method: "pickup",
            payment_method: "cod",
            deliveryAreaId: null,
            order_price: state.cartTotalPrice,
            comment: state.userInformation.comments,
            phone: state.userInformation.phoneNumber,
            timeslot: state.userInformation.timeSlots,
            coupon_code: null,
            items: state.cart.map((cartItem) => ({
                id: cartItem.id,
                qty: cartItem.quantity,
                variant: Object.keys(cartItem.selectedVariant).length !== 0 ? cartItem.selectedVariant.id : null,
                extrasSelected: cartItem.selectedExtras.map((extra) => ({
                    id: extra.id
                }))
            }))
        }

        try {
            setLoading(true);
            const { data } = await orderApi.post("/orders", reqBody);
            const { status, message, id } = data;
            if (status != false) {
                setLoading(false);
                const whatsappMessage = generateWhatsAppMessage(reqBody, id);
                sendWhatsAppMessage(whatsappMessage);
                toast.success("Order submitted successfully");
                dispatch({ type: SUBMIT_SUCCESS, payload: dispatch });
            } else {
                toast.error(message);
            }
        } catch (error) {
            toast.error("Order submission failed please try again");
            dispatch({ type: SUBMIT_ERROR });
        } finally {
            setLoading(false);
        }
    }

    const checkValidation = () => {
        if (!state.userInformation.phoneNumber) {
            toast.error("Phone number is required");
            setLoading(false);
            return false
        }
        // if (!state.userInformation.timeSlots) {
        //     toast.error("Pickup time is required");
        //     setLoading(false);
        //     return false
        // }
        return true
    }

    const generateWhatsAppMessage = (order, id) => {
        const { order_price, comment, phone, timeslot } = order;
        const { cart, hours } = state
        const pickupTime = hours.find((hour) => hour.id === timeslot)?.title;

        // Format order items
        const itemsList = cart.map(item => {
            let variantName = null;
            if (item.selectedVariant.options) {
                const options = JSON.parse(item.selectedVariant.options);
                variantName = options[Object.keys(options)[0]];
            }
            return `
                ${item.quantity} x ${item.name} - ${item.price.toFixed(2)} ${translate(CURRENCY)}
                ${variantName}
                ${item.selectedExtras.length > 0 ? item.selectedExtras.map(extra => `(${extra.name})`).join('-') : ''}
            `;
        }).join('\n');

        // Create the WhatsApp message
        const message = `New Order: *#${id}*
            Items:
            ${itemsList}
            *Total: ${order_price.toFixed(2)} ${translate(CURRENCY)}*
            ${comment ? `*Comments*: ${comment}` : ''}
            *Contact*: ${phone}
        `;
        return encodeURIComponent(message);
    };

    const sendWhatsAppMessage = (message) => {
        const link = `https://wa.me/${VENDOR_PHONE}?text=${message}`;
        window.location.href = link;
    };

    return (
        <>
            <ShoppingCartProvider state={state} dispatch={dispatch}>
                <div className="container-xxl position-relative p-0">
                    <Navbar translate={translate} i18n={i18n} dispatch={dispatch} />
                    <div className="container-xxl py-5 bg-dark hero-header mb-5">
                        <div className="container text-center my-5 pt-5 pb-4">
                            <h1 className="display-3 text-white mb-3 animated slideInDown">{translate('Checkout')}</h1>
                        </div>
                    </div>
                </div>
                {
                    state.cart.length > 0
                        ?
                        <>
                            <form onSubmit={postOrder} id="order-form" className="mt-4">
                                <div className="container-xxl position-relative p-0">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-xl-8">
                                                <UserInformation state={state} dispatch={dispatch} loading={loading} translate={translate} i18n={i18n} />
                                            </div>
                                            <div className="col-xl-4 mt-xl-0 mt-4 pb-xl-0 pb-5">
                                                <Items translate={translate} />
                                                <Payment state={state} translate={translate} i18n={i18n} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-btn bg-white border border-1 p-4 position-fixed bottom-0 w-100 d-lg-none d-block" style={{ zIndex: 999 }}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 p-0">
                                                <button type="submit" form="order-form" className="btn btn-success fw-bold py-3 rounded-2 w-100" disabled={loading}>
                                                    {
                                                        loading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                    }
                                                    <i className={`fab fa-whatsapp m${i18n.language === 'ar' ? 's' : 'e'}-2`}></i>
                                                    {translate('Complete order on whatsapp')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </>
                        :
                        <EmptyCart />
                }
                <Footer translate={translate} i18n={i18n} />
            </ShoppingCartProvider>
        </>
    )
}

export default CheckOut